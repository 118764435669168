body {
    background-color: #000;
    color: #FFFFFF;
    font-size: 20vw;
    height: 100vh;
    width: 100vw;
    overflow: hidden;
}

.mainTextDiv {
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
    justify-self: center;
    justify-items: center;
    width: 100%;
    height: 100%;
}